/* AppointmentDetail.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto; /* Allow scrolling if modal content exceeds viewport */

}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Ensure modal adapts to smaller screens */
    width: 500px;
    max-width: 90%;
}

@media (max-width: 480px) {
  .modal-content {
    width: 100%; /* Full width on very small screens */
    margin: 0 10px;
  }
}

.form-group {
    margin-bottom: 10px;
}

.full-width-input {
    width: 100%;
    padding: 5px;
}

.button-group {
    text-align: right;
}

.cancel-button,
.confirm-button {
    padding: 5px 10px;
    margin-top: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #ccc;
}

.confirm-button {
    background-color: blue;
    color: white;
}

/* 新增：成功消息的样式 */
.success-message {
    text-align: center;
}

.success-message p {
    margin-top: 20px;
    font-size: 16px;
}
