/* Calendar.css */

/* 样式容器 */
.calendar-container {
  max-width: 900px;
  margin: 0 auto;
}

/* 允许选择的日期（星期四和星期五）的样式 */
.fc-daygrid-day.allowed-day {
  background-color: rgba(230, 255, 230, 0.68); /* 淡绿色背景 */
}

.fc-daygrid-day.allowed-day:hover {
  background-color: rgba(204, 255, 204, 0.73); /* 悬停时背景稍微深一些 */
  cursor: pointer;
}

/* 不允许选择的日期（其他星期）的样式 */
.fc-daygrid-day.disallowed-day {
  background-color: #f9f9f9; /* 淡灰色背景 */
  color: #999;
}

.fc-daygrid-day.disallowed-day:hover {
  cursor: not-allowed;
}

/* 事件样式 */
.fc-event {
  background-color: #007bff; /* 蓝色背景 */
  border: none;
  color: #fff;
}

/* Remove underline from the weekday names in FullCalendar */
.fc-theme-standard th {
  text-decoration: none !important;
  color: gray !important; /* Set font color to black */
}

/* Remove underline from the dates in FullCalendar */
.fc-theme-standard a {
  text-decoration: none !important; /* Remove underline and override any other styles */
  color: gray !important; /* Set font color to black */
}


