.baseline {
    width: 70px;
    border-top: 1px solid #cc263d;
}

.fa-check-square-o{
    color: #FD8B25;
}

.green{
    color: rgb(172, 250, 172);
}