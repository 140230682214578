.open-hours-section {
    background-image: url('/src/assets/y0fv4tzh_heigh.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    display: flex;
    align-items: center; /* Center content vertically */
    margin: 0;
    padding: 0;

    font-family: 'Poppins', sans-serif;

}


h1 {
    font-size: 3rem;
}

.open-hours {

}

.bg-warning {
    background-color: var(--bs-warning) !important;
}