.contactus {
    background-color: #364d7c;
    font-family: 'Poppins', sans-serif
}
.round{
    horiz-align: center;
}

.allright {
    background-color: var(--bs-warning) !important;
    font-family: 'Poppins', sans-serif
}