.specialties-section {
    font-family: 'Arial', sans-serif;
}

.icon-box {
    display: flex;
    align-items: center;
}


.icon-style {
    font-size: 2rem; /* 默认小屏幕大小 */
    color: #FD8B25;
}


@media (min-width: 992px) {
    .icon-style {
        font-size: 2rem; /* 大屏幕大小 */
    }
}

@media (min-width: 1200px) {
    .icon-style {
        font-size: 2.5rem; /* 超大屏幕大小 */
    }
}

.icon-box-content h6 {
    margin: 0;
}

.text-accent-color {
    color: #FD8B25;
}
