.background-image {
    background-image: url('/src/assets/Slide_white_brown_feet_2.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    margin: 0;
    padding: 0;
}

.intro-section {
    background-position: bottom center;
    padding: 0 2.8em;
    border-bottom: none !important;
}



/*@media (max-width: 768px) {*/
/*    .background-image {*/
/*        background-size: contain; !* Adjust to fit the image fully *!*/
/*    }*/
/*    */
/*}*/
