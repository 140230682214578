body, html {
    margin: 0;
    padding: 0;
}

/*.col-md-10{*/

/*	width:95%;}*/

.text-accent-color{
		color:#FD8B25;
	}

.nav-link,
.nav-link.active {
	font-weight: 400;
	}
		

.nav-link:hover,
.nav-link.active:hover {
	color: #FD8B25;
	}




/* 	

img.wp-smiley,
img.emoji {
	display: inline !important;
	border: none !important;
	box-shadow: none !important;
	height: 1em !important;
	width: 1em !important;
	margin: 0 .07em !important;
	vertical-align: -0.1em !important;
	background: none !important;
	padding: 0 !important;
}

	.intro-section{
	padding: 24.93333333em 0 2.8em;
	border-bottom: none !important;
}

.about-section{
	border-bottom:3px solid #cc263d;

}

h2{

	font-size:45px;
}

.post-meta{

display:none;
}

.custom-logo{

	width:320px;
}

.contrast a.btn{
	background-color:#FD8B25;
	border:#FD8B25;

}

.doctor-profile-summary-details-trigger{

	background-color:#FD8B25;
}

.doctor-profile-summary-details-trigger:hover{

	background-color:#364D7C;
}

a.btn{
	background-color:#FD8B25;
	border:#FD8B25;
}


.open-hours-section .open-hours{

	background-color:#FD8B25;
}
.icon-box > i{
	color:#FD8B25;
}



.about-section{

	border-bottom:3px solid #FD8B25;
}

#footer-bottom{

	background-color:#FD8B25;
}

#custom-search-button{

	color:#FD8B25;
}

a:hover{

	color:#EE9376!important;
}

input[type="reset"], input[type="submit"], input[type="button"]{

border:1px solid #FD8B25;background-color:#FD8B25;

}

.open-hours p{

	color: black;
	font-size: 20px;
	font-weight: bold;
}

.widget-title{
	font-size: 35px;
}

.textwidget p{

	font-size:20px;
}

.menu-item-144 a:hover{

	color:#364D7C!important;}

.col-sm-4 a {

	font-size:30px;
} */

/* .picture-inline img{

	display: inline-block;
	float:left;
	margin:5px;
} */

/* .col-md-3{
width:100%;
}


.col-md-2{

	width:20%;
}



.post-navigation{
	display:none!important;
} */